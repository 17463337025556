<template>
  <div>
    <CCard
      class="p-4 cus-card"
      style="background: #3369c3"
      v-if="show_email_login || !IS_MZ_BRANDING"
    >
      <CCardBody style="width: 30vw; text-align: center">
        <CForm @submit.prevent="handleLogin">
          <h1 class="login-title mb-5" style="color: white">
            {{ $t("Login") }}
          </h1>
          <!--          <p style="color: white; font-size: 15px; margin-bottom: 50px">-->
          <!--            {{ $t("Login In to your Microsoft account") }}-->
          <!--          </p>-->
          <!-- <CInput
            :placeholder="$t('Username')"
            autocomplete="username email"
            v-model="login"
            name="login"
            class="mb-2 cus-input"
            required
          >
            <template #prepend-content>
              <CIcon name="cil-user" />
            </template>
          </CInput> -->
          <!-- <label class="login__label mb-20">
            <span>Email</span>
            <div class="login__input">
              <CIcon name="mail" size="custom" />
              <b-form-input
                v-model="login"
                autocomplete="username email"
                required
                name="login"
                :placeholder="$t('Username')"
              ></b-form-input>
            </div>
          </label>
          <label class="login__label">
            <span>Password</span>
            <div class="login__input">
              <CIcon name="password" size="custom" />
              <b-form-input
                v-model="password"
                required
                type="password"
                autocomplete="current-password"
                name="password"
                :placeholder="$t('Password')"
              ></b-form-input>
            </div>
          </label> -->

          <!-- <CInput
            :placeholder="$t('Password')"
            type="password"
            autocomplete="current-password"
            v-model="password"
            name="password"
            required
          >
            <template #prepend-content>
              <CIcon name="cil-lock-locked" />
            </template>
          </CInput> -->
          <!-- <div class="cus-row">
            <b-form-checkbox
              class="remember-me"
              v-model="status"
              name="rememberMe"
              size="lg"
            >
              Remember me
            </b-form-checkbox>

            <CCol class="text-right">
              <CButton
                color="link"
                class="px-0"
                :to="{ name: 'Forgot password' }"
                data-cy="forgot-password-link"
              >
                {{ $t("Forgot password?") }}
              </CButton>
            </CCol>
          </div> -->
          <CRow class="mt-2">
            <CCol
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <!-- <CButton
                color="primary"
                class="px-4 cus-button"
                type="submit"
                data-cy="login-button"
              >
                {{ $t("Login") }}
              </CButton> -->
              <b-button style="background: white" @click="handleLoginMSAL">
                <CSpinner v-if="oauthLoading" />
                <div v-else>
                  <img
                    src="/img/ForvisMazars.png"
                    alt=""
                    style="width: 100px; margin-top: -5px"
                  />
                  <div>
                    {{ $t("Sign in via") }}
                    <br />
                    Microsoft™
                  </div>
                </div>
              </b-button>
            </CCol>
          </CRow>
        </CForm>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { API_ENDPOINTS } from "@/constants/common";
import { ActiveUser } from "@/services/user";
import { IS_MZ_BRANDING } from "@/constants/config";
import { AuthService } from "@/services/auth";
import { users } from "@/services/users";
import { getSearchParam } from "@/utils/helpers";
import store from "../../store";
import { BASE_URL } from "@/constants/config";

export default {
  name: "Login",
  props: ["show_email_login"],
  data: function () {
    return {
      IS_MZ_BRANDING,
      login: "",
      password: "",
      mzAuthUri:
        "https://logon.mazars.global/as/authorization.oauth2?response_type=code&client_id=DataMorph&redirect_uri=https://https://datamorph-dev.zettafox.com/&scope=openid%20email",
      oauthLoading: false,
      status: false,
    };
  },
  async mounted() {
    this.redirectIfLoggedIn();
    this.redirectAuthenticated();
    this.tryLoginWithAccessCode();
    if (IS_MZ_BRANDING) {
      this.fetchMzAuthUri();
    }
  },
  methods: {
    async redirectIfLoggedIn() {
      const storedCode = localStorage.getItem("code");
      const flowId = localStorage.getItem("flow_id");

      if (storedCode) {
        let url = `${BASE_URL}api/users/msi_login/${flowId}?${storedCode}`;
        const { data, res } = await users.getLoginToken(url);
        this.oauthLoading = true;
        if (res.status === 200) {
          localStorage.removeItem("code");
          localStorage.removeItem("flow_id");
          await ActiveUser.set({
            accessToken: data.access_token,
            email: data.email,
          });
          this.redirectAfterLogin();
          this.oauthLoading = false;
        }
        if (!data.success) {
          alert(data.message);
          this.oauthLoading = false;
        }
      }
    },
    async redirectAfterLogin() {
      const pathToLoadAfterLogin = localStorage.getItem("pathToLoadAfterLogin");
      localStorage.removeItem("pathToLoadAfterLogin");

      let userRole = await users.getActiveUserInfo();
      userRole = userRole["roles"][0];
      localStorage.setItem("userRole", userRole);
      this.$router.replace(
        (!pathToLoadAfterLogin?.includes("login") && pathToLoadAfterLogin) ||
          "/"
      );
    },
    redirectAuthenticated() {
      if (ActiveUser.get()) this.redirectAfterLogin();
    },
    async tryLoginWithAccessCode() {
      const code = getSearchParam("code");
      const state = getSearchParam("state");
      if (code && state) {
        this.oauthLoading = true;
        const { data } = await request({
          url: API_ENDPOINTS.mzAuthCallback,
          query: {
            code,
            state,
          },
        });
        const { access_token } = data;
        ActiveUser.setToken(access_token);
        this.redirectAfterLogin();
      }
    },

    async fetchMzAuthUri() {
      const { data } = await request({
        url: API_ENDPOINTS.mzAuth,
      });
      this.mzAuthUri = data.authorization_url;
    },
    async handleLogin() {
      try {
        const res = await AuthService.login({
          email: this.login,
          password: this.password,
        });
      } catch (e) {
        this.$toasted.error("Invalid Username or Password", {
          position: "top-right",
          duration: 5000,
        });
      }

      this.redirectAuthenticated();
    },

    async handleLoginMSAL() {
      this.oauthLoading = true;
      const data = await users.getMsiLogin();
      localStorage.setItem("flow_id", data.flow_id);
      window.location.href = data.link;
    },
  },
};
</script>

<style lang="scss">
.cus-card {
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(12, 32, 68, 0.05);
  border-radius: 20px;
}

.cus-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 30px;
  .custom-checkbox .custom-control-label {
    font-family: "Lato";
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #0c2044 !important;
    margin-bottom: 5px;
  }

  .custom-checkbox .custom-control-label::before {
    background: #f8faff;
    border: 1px solid #e7edfb;
    border-radius: 2px;
  }
}
</style>
